const tokenStorageKey = "canvas:local:token";
const userIdStorageKey = "canvas:local:userid";
const trackIdStorageKey = "canvas:local:trackid";

export const getToken = () => {
  return localStorage.getItem(tokenStorageKey);
};

export const setToken = (token: string) => {
  return localStorage.setItem(tokenStorageKey, token);
};

export const getUserId = () => {
  return localStorage.getItem(userIdStorageKey);
};

export const setUserId = (id: string) => {
  return localStorage.setItem(userIdStorageKey, id);
};

export const getTrackId = () => {
  return localStorage.getItem(trackIdStorageKey);
};

export const setTrackId = (id: string) => {
  return localStorage.setItem(trackIdStorageKey, id);
};

export const clearUser = () => {
  localStorage.removeItem(tokenStorageKey);
  localStorage.removeItem(userIdStorageKey);
  sessionStorage.clear();
  localStorage.removeItem(trackIdStorageKey);
  localStorage.removeItem('user_id')

  const win = window as any;
  if (win && win.growsurf) {
    win.growsurf.logout();
  }
};

export const setLoginInfo = (data: any) => {
  setToken(data.token);
  setUserId(data.userId);
};
